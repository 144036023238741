
import {
    defineAsyncComponent, defineComponent, ref, watch, computed
} from 'vue';
import { TabsData } from '@/views/pm/device/types/all.type';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb/index';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import { community } from '@/data';
import { isCNServer } from '@/util/location';

export default defineComponent({
    components: {
        propertyBreadCrumb
    },
    props: {
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const breadCrumbHeaders = [
            {
                label: WordList.OrderDevice,
                path: `/${propertyBaseRouter}/${propertySubRouter.device}`
            }
        ];

        // 第三方设备样子的展示
        const isShowThirdPartyDeviceUI = ref(true);
        // 控制akuvox设备和第三方设备展示的数据
        const tabsButtonName = computed(() => props.type);
        const tabsButtonData: TabsData = {
            intercom: {
                label: WordList.IntercomDevices,
                name: 'intercom',
                component: defineAsyncComponent(() => import('@/views/pm/device/components/intercom-device.vue'))
            },
            thirdPartyDevices: {
                label: WordList.ThirdPartyDevices,
                name: 'thirdPartyDevices',
                component: ''
            }
        };

        // 控制第三方设备展示标签页数据
        const baseTabs = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(() => import('@/views/pm/device/components/control-devices.vue'))
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(() => import('@/views/pm/device/components/new-community-camera.vue'))
            }
        };
        const tabsData = ref<TabsData>(baseTabs);
        const tabsActiveName = ref(props.deviceType);

        // 6.5 新小区且有第三方设备展示新标签页 zyc 2022/8/29
        // 6.5.2 合并sdk二期
        watch([
            () => community.ProjectType.value,
            () => community.isNew.value,
            () => community.hasThirdPartyDevice.value], () => {
            const isShowCamera = community.ProjectType.value === 'community'
                && community.isNew.value
                && community.hasThirdPartyDevice.value;
            const isShowControl = isCNServer()
                && community.isNew.value
                && community.ProjectType.value === 'community'
                && community.hasControlDevice.value;
            isShowThirdPartyDeviceUI.value = isShowCamera || isShowControl;
            if (isShowCamera && !isShowControl) {
                tabsData.value = { camera: baseTabs.camera };
                if (props.deviceType === '') {
                    tabsActiveName.value = 'camera';
                }
            } else if (!isShowCamera && isShowControl) {
                tabsData.value = { control: baseTabs.control };
                if (props.deviceType === '') {
                    tabsActiveName.value = 'control';
                }
            } else {
                tabsData.value = baseTabs;
                if (props.deviceType === '') {
                    tabsActiveName.value = 'control';
                }
            }
        }, {
            immediate: true
        });

        const changeTabsButton = (key: string) => {
            // router.push(`/${propertyBaseRouter}/${propertySubRouter.device}?type=${key}&deviceType=${props.deviceType}`);
            router.push(
                {
                    path: `/${propertyBaseRouter}/${propertySubRouter.device}`,
                    query: {
                        type: key,
                        deviceType: props.deviceType
                    }
                }
            );
        };

        const changeTab = (name: any) => {
            // router.push(`/${propertyBaseRouter}/${propertySubRouter.device}?type=${props.type}&deviceType=${name.paneName}`);
            router.push(
                {
                    path: `/${propertyBaseRouter}/${propertySubRouter.device}`,
                    query: {
                        type: props.type,
                        deviceType: name.paneName
                    }
                }
            );
        };
        console.log(12323);
        console.log(12323);

        return {
            isShowThirdPartyDeviceUI,
            tabsButtonName,
            tabsButtonData,
            breadCrumbHeaders,
            tabsActiveName,
            tabsData,
            changeTabsButton,
            changeTab
        };
    }
});
